<template>
  <g
    class="flowArea"
  >
    <svg
      ref="initAreaSvg"
    >
      <!-- INIT AREA START -->
      <circle
        v-if="flowArea.initArea"
        :cx="flowArea.initArea.position.pan"
        :cy="-flowArea.initArea.position.tilt"
        :r="7 * zoomFactor"
        class="initArea"
        :fill="edit ? '#00c0ef' : 'lightgray'"
      />

      <!-- INIT AREA TEXT -->
      <text
        v-if="flowArea.initArea"
        :x="flowArea.initArea.position.pan"
        :y="-flowArea.initArea.position.tilt"
        :font-size="7 * zoomFactor"
        :dy="2.6 * zoomFactor"
        font-weight="font-weight-bold"
        text-anchor="middle"
        font-family="Arial"
        :fill="edit ? 'white' : 'black'"
      >
        {{ flowArea.id }}
        <!-- MOUSE OVER -->
        <title>{{ flowArea.id }}. {{ flowArea.name }} {{ edit }}</title>
      </text>
    </svg>

    <!-- INIT AREA END -->

    <!-- RELEASE AREA START -->

    <g class="releaseAreaWrapper">
      <polygon
        v-if="flowArea.releaseArea && flowArea.releaseArea.length"
        :points="createPolygon(flowArea.releaseArea)"
        :stroke-width="`${ 1.5 * zoomFactor }px`"
        :fill="edit ? 'rgba(255, 140, 0, 0.685)' : 'lightgray'"
        :stroke="edit ? 'darkorange' : 'lightgray'"
        stroke-linejoin="round"
      />
      <text
        v-if="flowArea.releaseArea && flowArea.releaseArea.length"
        :x="center(createPolygon(flowArea.releaseArea)).x"
        :y="center(createPolygon(flowArea.releaseArea)).y"
        dominant-baseline="middle"
        text-anchor="middle"
        :font-size="7 * zoomFactor"
      >
        R{{ flowArea.id }}
      </text>
    </g>

    <!-- RELEASE AREA END -->

    <!-- TURNOFF AREA START -->

    <g class="turnOffAreaWrapper">
      <polygon
        v-if="flowArea.turnOffArea && flowArea.turnOffArea.length"
        :points="createPolygon(flowArea.turnOffArea)"
        :stroke-width="`${ 1.5 * zoomFactor }px`"
        :fill="edit ? 'rgba(255, 72, 0, 0.5)' : 'lightgray'"
        :stroke="edit ? 'red' : 'lightgray'"
        stroke-linejoin="round"
      />
      <text
        v-if="flowArea.turnOffArea && flowArea.turnOffArea.length"
        :x="center(createPolygon(flowArea.turnOffArea)).x"
        :y="center(createPolygon(flowArea.turnOffArea)).y"
        dominant-baseline="middle"
        text-anchor="middle"
        :font-size="7 * zoomFactor"
      >
        T{{ flowArea.id }}
      </text>
    </g>

    <!-- TURNOFF AREA END -->

    <!-- DRAGGABLE START -->
    <!-- ALL DRAG POINTS ARE TOUCHABLE ALSO ON OVERLAPPING -->

    <template v-if="edit && flowArea.releaseArea">
      <circle
        v-for="(point, index) in flowArea.releaseArea"
        ref="releaseAreaPointHandle"
        :key="`flowArea-releaseArea-${ index }`"
        :index="index"
        :r="2 * zoomFactor"
        :cx="point.pan"
        :cy="-point.tilt"
        :stroke-width="2 * zoomFactor"
        :releaseindex="index"
        class="point-handle cursor-move"
        fill="white"
        fill-opacity="0.5"
        stroke="darkorange"
      />
    </template>

    <template v-if="edit && flowArea.turnOffArea">
      <circle
        v-for="(point, index) in flowArea.turnOffArea"
        ref="turnOffAreaPointHandle"
        :key="`flowArea-turnOffArea-${ index }`"
        :index="index"
        :r="2 * zoomFactor"
        :cx="point.pan"
        :cy="-point.tilt"
        :stroke-width="2 * zoomFactor"
        :releaseindex="index"
        class="point-handle cursor-move"
        fill="white"
        fill-opacity="0.5"
        stroke="darkred"
      />
    </template>

    <!-- DRAGGABLE END -->
  </g>
</template>

<script>
import interact from 'interactjs';

export default {
  name: 'FlowArea',
  props: {
    flowArea: {
      type: Object,
      required: true,
      default: () => ({
        id: 0,
        name: 'flowName',
        initArea: {
          position: {
            pan: 0,
            tilt: 0,
          }
        },
        releaseArea: [],
      })
    },
    edit: {
      type: Boolean,
      required: true,
    },
    color: {
      type: String,
      required: false,
      default () {
        return "lightcoral";
      }
    },
    scale: {
      type: Object,
      required: false,
      default: () => ({
        scaleX: 1,
        scaleY: 1,
      })
    },
    zoomFactor: {
      type: Number,
      required: false,
      default () {
        return 1;
      }
    },
  },
  mounted: function () {
    this.addDraggable();
  },
  beforeDestroy: function () {
    this.removeDraggable();
  },
  methods: {
    update () {
      this.$nextTick(() => {
        this.removeDraggable();
        this.addDraggable();
      });
    },
    center (points) {
      let pointsArray = points.split(' ');
      pointsArray.shift();
      let x = 0;
      let y = 0;
      pointsArray.forEach(point => {
        let splitPoint = point.split(',');
        x += parseFloat(splitPoint[0]);
        y += parseFloat(splitPoint[1]);
      });
      let centroid = { x: x / pointsArray.length, y: y / pointsArray.length};
      return centroid;
    },
    createPolygon (positions) {
      if (!positions.length) {
        return '';
      }

      if (typeof positions[0].pan === 'undefined') {
        return '';
      }
      let result = ``;
      for (let index = 0; index < positions.length; index++) {
        result = `${ result } ${ positions[index].pan },${ -positions[index].tilt }`;
      }
      return result;
    },
    round (number) {
      //Round values to 2 decimal digits
      return Math.round(number * 1e2) / 1e2;
    },
    removeDraggable () {
      if (!this.edit) {
        return;
      }

      if (this.$refs.releaseAreaPointHandle) {
        this.$refs.releaseAreaPointHandle.forEach(o => {
          interact(o).unset();
        });
      }

      if (this.$refs.turnOffAreaPointHandle) {
        this.$refs.turnOffAreaPointHandle.forEach(o => {
          interact(o).unset();
        });
      }

      if (this.$refs.initAreaSvg) {
        interact(this.$refs.initAreaSvg).unset();
      }
    },
    addDraggable () {
      if (!this.edit) {
        return;
      }

      if (this.$refs.releaseAreaPointHandle) {
        this.$refs.releaseAreaPointHandle.forEach(o => {
          interact(o)
          .draggable({ max: Infinity, restrict: { elementRect: { top: 0, left: 0, bottom: 1, right: 1 } },
            onmove: (event) => {
              let index = event.target.attributes.index.value;
              this.flowArea.releaseArea[index].pan += event.dx * this.scale.scaleX;
              this.flowArea.releaseArea[index].tilt -= event.dy * this.scale.scaleY;
              if(this.round(this.flowArea.releaseArea[index].pan) > 170) {
                this.flowArea.releaseArea[index].pan = 170;
              }
              else if(this.round(this.flowArea.releaseArea[index].pan) < -170) {
                this.flowArea.releaseArea[index].pan = -170;
              }
              else {
                this.flowArea.releaseArea[index].pan = this.round(this.flowArea.releaseArea[index].pan);
              }

              if(this.round(this.flowArea.releaseArea[index].tilt) > 80) {
                this.flowArea.releaseArea[index].tilt = 80;
              }
              else if(this.round(this.flowArea.releaseArea[index].tilt) < -80) {
                this.flowArea.releaseArea[index].tilt = -80;
              }
              else {
                this.flowArea.releaseArea[index].tilt = this.round(this.flowArea.releaseArea[index].tilt);
              }
              this.$emit('changePtz', this.flowArea.releaseArea[index]);
            }
          });
        });
      }

      if (this.$refs.turnOffAreaPointHandle) {
        this.$refs.turnOffAreaPointHandle.forEach(o => {
          interact(o)
          .draggable({ max: Infinity, restrict: { elementRect: { top: 0, left: 0, bottom: 1, right: 1 } },
            onmove: (event) => {
              let index = event.target.attributes.index.value;
              this.flowArea.turnOffArea[index].pan += event.dx * this.scale.scaleX;
              this.flowArea.turnOffArea[index].tilt -= event.dy * this.scale.scaleY;
              if(this.round(this.flowArea.turnOffArea[index].pan) > 170) {
                this.flowArea.turnOffArea[index].pan = 170;
              }
              else if(this.round(this.flowArea.turnOffArea[index].pan) < -170) {
                this.flowArea.turnOffArea[index].pan = -170;
              }
              else {
                this.flowArea.turnOffArea[index].pan = this.round(this.flowArea.turnOffArea[index].pan);
              }

              if(this.round(this.flowArea.turnOffArea[index].tilt) > 80) {
                this.flowArea.turnOffArea[index].tilt = 80;
              }
              else if(this.round(this.flowArea.turnOffArea[index].tilt) < -80) {
                this.flowArea.turnOffArea[index].tilt = -80;
              }
              else {
                this.flowArea.turnOffArea[index].tilt = this.round(this.flowArea.turnOffArea[index].tilt);
              }
              this.$emit('changePtz', this.flowArea.turnOffArea[index]);
            }
          });
        });
      }

      if (this.$refs.initAreaSvg) {
        interact(this.$refs.initAreaSvg)
        .draggable({ max: Infinity, restrict: { elementRect: { top: 0, left: 0, bottom: 1, right: 1 } },
          onmove: (event) => {
            this.flowArea.initArea.position.pan += event.dx * this.scale.scaleX;
            this.flowArea.initArea.position.tilt -= event.dy * this.scale.scaleY;
            if(this.round(this.flowArea.initArea.position.pan) > 170) {
              this.flowArea.initArea.position.pan = 170;
            }
            else if(this.round(this.flowArea.initArea.position.pan) < -170) {
              this.flowArea.initArea.position.pan = -170;
            }
            else {
              this.flowArea.initArea.position.pan = this.round(this.flowArea.initArea.position.pan);
            }

            if(this.round(this.flowArea.initArea.position.tilt) > 80) {
              this.flowArea.initArea.position.tilt = 80;
            }
            else if(this.round(this.flowArea.initArea.position.tilt) < -80) {
              this.flowArea.initArea.position.tilt = -80;
            }
            else {
              this.flowArea.initArea.position.tilt = this.round(this.flowArea.initArea.position.tilt);
            }
            this.$emit('changePtz', this.flowArea.initArea.position);
          }
        });
      }
    },
  }
}
</script>

<style scoped>
svg {
  overflow: visible;
}
g {
  opacity: 0.7;
}
.initArea {
  stroke: none;
}
</style>
